export default function useStudioProduct() {
  const categoryOptions = [
    { value: 'wash', text: '洗髮' },
    { value: 'care', text: '護髮' },
    { value: 'handCare', text: '護手' },
    { value: 'scalp', text: '頭皮' },
    { value: 'style', text: '造型' },
    { value: 'dye', text: '染膏' },
    { value: 'medicine', text: '藥水' },
    { value: 'oil', text: '精油' },
    { value: 'drink', text: '茶飲' },
    { value: 'other', text: '其他耗材' }]

  return { categoryOptions }
}
