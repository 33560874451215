import studioProductCreateRequest from 'request/studio-product/create'
import studioProductUpdateRequest from 'request/studio-product/update'
import studioProductDeleteRequest from 'request/studio-manage-product/studio-product-delete'
import hairBrandsRequest from 'request/studio-product/hair-brands'
import useStudioProduct from 'hooks/studio/studio-product'

export default function StudioProductPanel() {
  const t = useI18n()
  const { categoryOptions } = useStudioProduct()
  const { state, mutate } = useStatic({
    product: null,
    isOfficialStock: false,
    visible: false,
    callback: null,
    categories: [],
    units: [],
    title: '',
    studioId: null
  })

  const formDefaultValue = {
    hairBrand: '',
    categoryType: null,
    type: 'sale',
    name: '',
    cost: 0,
    originalPrice: 0,
    price: 0,
    inventory: 0,
    minInventory: 0,
    restockInventory: 0,
    exhibitInventory: 0,
    notes: '',
    costType: 'managerPrepay',
    unit: '',
    isOpenable: false,
    forceEmployeePurchase: false,
    checkCategory: ''
  }

  const form = useForm({
    hairBrand: { rule: 'required', value: formDefaultValue.hairBrand },
    categoryType: { value: formDefaultValue.categoryType },
    type: { value: formDefaultValue.type },
    name: { rule: 'required', value: formDefaultValue.name },
    cost: { value: formDefaultValue.cost },
    originalPrice: { value: formDefaultValue.originalPrice, rule: 'onlyDigit' },
    price: { value: formDefaultValue.price, rule: 'onlyDigit' },
    inventory: { value: formDefaultValue.inventory, rule: 'onlyDigit' },
    minInventory: { value: formDefaultValue.minInventory, rule: 'onlyDigit' },
    restockInventory: { value: formDefaultValue.restockInventory, rule: 'onlyDigit' },
    exhibitInventory: { value: formDefaultValue.exhibitInventory, rule: 'onlyDigit' },
    notes: { value: formDefaultValue.notes },
    costType: { value: formDefaultValue.costType },
    unit: { value: formDefaultValue.unit },
    isOpenable: { value: false },
    forceEmployeePurchase: { value: false },
    checkCategory: { value: formDefaultValue.checkCategory }
  })

  h.openStudioProduct = async({ product, studioId, callback }) => {
    state.product = product
    state.isOfficialStock = product?.isOfficialStock
    state.studioId = studioId
    state.callback = callback
    state.visible = true
    state.title = !!product ? '編輯產品' : '新增產品'
    updateForm(product)
    mutate()
  }

  const updateForm = product => {
    if (!product) return

    const { hairBrand, ...rest } = product
    form.updateValues({
      hairBrand: { id: hairBrand.id, value: hairBrand.name },
      ...rest
    })
  }

  const closePanel = () => {
    state.visible = false
    form.updateValues(formDefaultValue)
  }

  const onSubmit = async() => {
    const { name, hairBrand, cost, type, unit, originalPrice, price, notes, costType, categoryType, minInventory, restockInventory, exhibitInventory, isOpenable, forceEmployeePurchase, checkCategory } = form
    let costValue = typeof cost.value !== 'number' ? cost.value.toNumber() : cost.value
    if (type.value === 'sale') costValue = Math.floor(costValue)
    const product = {
      name: name.value,
      cost: costValue,
      originalPrice: originalPrice.value,
      price: price.value,
      notes: notes.value || '',
      costType: costType.value,
      categoryType: categoryType.value,
      type: type.value,
      unit: unit.value,
      isOpenable: isOpenable.value,
      forceEmployeePurchase: forceEmployeePurchase.value,
      minInventory: minInventory.value,
      restockInventory: restockInventory.value,
      exhibitInventory: exhibitInventory.value,
      checkCategory: checkCategory.value.trim()
    }
    if (!isValid(product)) return
    const requestResolver = state.product ? studioProductUpdateRequest : studioProductCreateRequest
    await requestResolver({
      id: state.product?.id,
      studioId: state.studioId,
      brand: parseInt(hairBrand.value.id),
      ...product
    })

    $alert(t('common:alert.submitSuccess'))
    state.callback && state.callback()
    closePanel()
  }

  const isValid = product => {
    if (product.type !== 'sale') return true
    if (product.cost < product.price) return true
    $alert('成本必須小於售價', 'error')
    return false
  }

  const onDelete = async() => {
    if (!confirm('確定要刪除嗎?')) return

    const { id } = state.product
    await studioProductDeleteRequest(id)
    $alert(t('common:deleteSuccess'))
    state.callback && state.callback()
    closePanel()
  }

  const renderBody = () => {
    if (!state.visible) return
    return <Body form={ form } state={ state } closePanel={ closePanel } categoryOptions={ categoryOptions } />
  }

  const renderDeleteButton = () => {
    if (!state.product) return
    return <C.Button uid='productCreate.delete' className='w-full' variant='outlined'
      onClick={ onDelete }>{t('common:delete')}</C.Button>
  }

  return (
    <C.Modal
      visible={ state.visible }
      close={ closePanel }
      noPadding
    >
      <C.ModalHeader title={ state.title }>
        {renderBody()}
        <div className='relative'><C.Blur /></div>
        <div className='flex space-x-5 shrink-0 p-5'>
          {renderDeleteButton()}
          <C.Button uid='productCreate.submit' className='w-full'
            check={ form } onClick={ onSubmit }>
            {t('common:submit')}
          </C.Button>
        </div>
        {/* <OpenProductList/> */}
      </C.ModalHeader>
    </C.Modal>
  )
}

const Body = ({ form, state, categoryOptions }) => {
  const brandLoadMore = ({ keyword, offset, limit }) => hairBrandsRequest(keyword, offset, limit)
  const unitOptions = store.studioProductUnits.map(text => ({ value: text, text }))
  unitOptions.push({ value: '', text: '(空)' })

  return <div className='overflow-scroll flex flex-col p-5'>
    <div className='py-3'>
      <C.AsyncSelectForm
        label='產品品牌'
        field='hairBrand'
        form={ form }
        optionFormat={ option => ({
          ...option,
          key: option.id,
          value: option.name
        }) }
        optionRender={ option => (
          <div className='flex items-center justify-center overflow-hidden'>
            <div className='mx-3'>
              <C.Image
                className='w-9 h-9'
                src={ option.url }
                alt='logo'
              />
            </div>
            <div className='truncate ml-2'>{option.value}</div>
          </div>
        ) }
        loadMore={ brandLoadMore }
        disabled={ state.isOfficialStock }
      />

      <C.InputForm
        variant='standard'
        label='產品名稱'
        form={ form }
        field='name'
        fullWidth
        disabled={ state.isOfficialStock }
      />

      <div className='pb-5'>
        <C.SelectForm
          label='類型'
          form={ form }
          field='type'
          options={ [
            { value: 'sale', text: '店販' },
            { value: 'consumable', text: '店用' }
          ] }
          className='w-full'
          disabled={ state.isOfficialStock }
        />
      </div>

      <div className='pb-5'>
        <C.SelectForm
          form={ form }
          label='分類'
          field='categoryType'
          options={ categoryOptions }
          className='w-full'
          disabled={ state.isOfficialStock }
        />
      </div>

      <div className='pb-5'>
        <C.SelectForm
          form={ form }
          label='單位'
          field='unit'
          options={ unitOptions }
          className='w-full'
          disabled={ state.isOfficialStock }
        />
      </div>

      <div className='flex space-x-3'>
        <C.InputForm
          variant='standard'
          label='原價'
          form={ form }
          field='originalPrice'
          fullWidth
          number
          disabled={ state.isOfficialStock }
        />
        <C.InputForm
          variant='standard'
          label='售價'
          form={ form }
          field='price'
          fullWidth
          number
          disabled={ state.isOfficialStock }
        />
        <C.InputForm
          variant='standard'
          label='成本'
          form={ form }
          field='cost'
          fullWidth
          disabled={ state.isOfficialStock }
        />
      </div>

      <div className='flex space-x-3'>
        <C.InputForm
          variant='standard'
          label='最低庫存'
          form={ form }
          field='minInventory'
          number
          fullWidth
          disabled={ state.isOfficialStock }
        />
        <C.InputForm
          variant='standard'
          label='補貨目標'
          form={ form }
          field='restockInventory'
          number
          fullWidth
          disabled={ state.isOfficialStock }
        />
        <C.InputForm
          variant='standard'
          label='陳列數量'
          form={ form }
          field='exhibitInventory'
          number
          fullWidth
        />
      </div>

      <div className='pb-5'>
        <C.SelectForm
          label='是否可開瓶'
          form={ form }
          field='isOpenable'
          options={ [
            { value: true, text: '是' },
            { value: false, text: '否' }
          ] }
          className='w-full'
          disabled={ state.isOfficialStock }
        />
      </div>

      <div className='pb-5'>
        <C.SelectForm
          label='進貨方式'
          form={ form }
          field='costType'
          options={ [
            { value: 'managerPrepay', text: '店長統一進貨' },
            { value: 'selfSourcing', text: '設計師自行進貨' },
            { value: 'officialStock', text: '美配統一進貨' }
          ] }
          className='w-full'
          disabled={ state.isOfficialStock }
        />
      </div>

      <C.InputForm
        variant='standard'
        label='盤點大分類'
        form={ form }
        field='checkCategory'
        fullWidth
        disabled={ state.isOfficialStock }
      />

      <div className='pb-5'>
        <C.SelectForm
          label='僅可員購'
          form={ form }
          field='forceEmployeePurchase'
          options={ [
            { value: true, text: '是' },
            { value: false, text: '否' }
          ] }
          className='w-full'
        />
      </div>

      <C.InputForm
        variant='standard'
        label='備註'
        form={ form }
        field='notes'
        fullWidth
        multiline
      />
    </div>
  </div>
}
